<template>
  <div>
    <el-card>
      <div class="input">
        优惠券名称：
        <el-select
          v-model="queryInfo.merchantId"
          placeholder=""
          clearable
          @change="searchCoupon"
        >
          <el-option
            v-for="item in couponTotal"
            :key="item.id"
            :label="item.merchantName"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <el-table
        border
        stripe
        :data="couponTotal"
      >
        <el-table-column
          label="商户id"
          prop="id"
        ></el-table-column>
        <el-table-column
          label="店铺名称"
          prop="merchantName"
        ></el-table-column>
        <el-table-column
          label="优惠卷使用数量"
          prop="couponNum"
        ></el-table-column>
        <el-table-column
          label="优惠卷统计金额"
          prop="couponAmount"
        ></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-link
              :underline="false"
              type="primary"
              @click="detail(scope.row.id)"
            >详情</el-link>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      queryInfo: {
        limit: 15,
        pageNo: 1,
        merchantId: ''
      },
      couponTotal: [],
      total: 0

    }
  },
  created () {
    this.getCouponList()
  },
  methods: {
    searchCoupon () {
      this.queryInfo.pageNo = 1
      this.getCouponList()
    },
    async getCouponList () {
      const { data: res } = await this.$http.get('/agriculturalCoupon/useCondition', {
        params: this.queryInfo
      })
      if (res.code === 0) {
        this.couponTotal = res.data.data.map(item => ({
          ...item,
          couponAmount: item.couponAmount / 100
        }))
        this.total = res.data.total
      } else {
        this.$message.error(res.message)
      }
      // console.log(res)
    },
    // limit变化，调用列表接口
    handleSizeChange (newSize) {
      this.queryInfo.limit = newSize
      this.getCouponList()
    },
    // 页码变化，调用接口
    handleCurrentChange (newPage) {
      this.queryInfo.pageNo = newPage
      this.getCouponList()
    },
    detail (id) {
      this.$router.push({ path: '/statistics-detail', query: { id: id } })
    }
  }
}
</script>

<style lang="less" scoped>
.input {
  display: flex;
  width: 340px;
  .el-select {
    position: relative;
    top: -10px;
  }
}
</style>
